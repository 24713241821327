export default {
    init() {
        
        const filterButtons = document.getElementsByClassName("filter-button-blog");

		if (filterButtons) {
			filterButtons.forEach(button => {
                button.addEventListener("click", function () {

                    // remove active class when clicking same button again
                    if (button.classList.contains("active")) {
                        button.classList.remove("active");
                    } else {
                        button.classList.add("active");
                    }
                    
                    if (button.classList.contains("swiper-slide-active")) {
                        button.classList.remove("swiper-slide-active");
                    } else {
                        button.classList.add("swiper-slide-active");
                    }
                    
                    const activeButtons = document.querySelectorAll(".filter-button-blog.active");
                    console.log(activeButtons);

                    activeButtons.forEach(activeButton => {
                        activeButton.classList.remove("active");
                    });

                    const activeSlideButtons = document.querySelectorAll(".filter-button-blog.swiper-slide-active");
                    
                    activeSlideButtons.forEach(activeButton => {
                        activeButton.classList.remove("swiper-slide-active");
                    });

                    button.classList.add("swiper-slide-active");
                    button.classList.add("active");

                  });
            });
		}
    },
}