export default {
    init() {
        
        const header = document.getElementById("header");
        const mainHeader = document.getElementsByClassName("main-header");
        
        if (header) {
            window.addEventListener("scroll", function () {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                if (scrollTop > 80) {
                    header.classList.add("header-shadow");
                    if (!header.classList.contains("bg-white")) {
                        if (scrollTop > mainHeader[0].offsetHeight) {
                            header.classList.add("header-white");
                        } else {
                            if (header.classList.contains("header-white")) {
                                header.classList.remove("header-white");
                            }
                        }
                    }
                } else {
                    header.classList.remove("header-shadow");
                    if (header.classList.contains("header-white")) {
                        header.classList.remove("header-white");
                    }
                }
            });
        }
    },
}