import * as Klaro from "klaro/dist/klaro-no-css";

export default {

	init() {

		let facebookPixelLoaded = false;

		const config = {
			elementID: "cookie-banner",
			storageMethod: "cookie",
			storageName: "klaro-cookie",
			mustConsent: true, // Cookie banner is a Modal
			acceptAll: true,
			hideDeclineAll: false,
			translations: {
				de: {
					privacyPolicyUrl: "http://starter-project.test/datenschutz",
					consentNotice: {
						description: "Wir nutzen Cookies, um dir bestmögliche Funktionalitäten zu bieten. Mehr Infos dazu findest du hier: {privacyPolicy}",
						learnMore: "Einstellungen",
					},
					consentModal: {
						title: "Cookie Einstellungen",
						description: "Wir möchten Ihre Daten für folgende Zwecke verwenden dürfen.",
						privacyPolicy: {
							text: "Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.",
							name: "Datenschutzerklärung (Name)",
						},
					},
					ok: "Alle akzeptieren",
					decline: "Funktionale Cookies",
					acceptSelected: "Auswahl speichern",
					save: "Speichern",
					close: "Schließen",
					acceptAll: "Alle akzeptieren",
					purposes: {
						functional: "Funktionale Cookies",
						analytics: "Statistiken",
					},
				},
				en: {
					privacyPolicyUrl: "http://starter-project.test/en/data-protection-policy",
					consentNotice: {
						description: "We use cookies to offer you the best possible functionality. You can find more information here: {privacyPolicy}",
						learnMore: "Settings",
					},
					consentModal: {
						title: "Cookie settings",
						description: "We use cookies to offer you the best possible functionality. ",
						privacyPolicy: {
							text: "Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.",
							name: "Privacy Policy (Name)",
						},
					},
					ok: "Accept all",
					decline: "Functional cookies",
					acceptSelected: "Save selection",
					save: "Save",
					close: "Close",
					acceptAll: "Accept all",
					purposes: {
						functional: "Functional cookies",
						analytics: "Statistic",
					},
				},
			},
			services: [
				{
					name: "functional",
					title: "Functional cookies",
					default: true,
					purposes: ["functional"],
					cookies: [
						["CraftSessionId"],
						["*_identity"],
						["*_username"],
						["CRAFT_CSRF_TOKEN"],
					],
					required: true,
				},
				{
					name: "gtm",
					title: "Google Tag Manager",
					purposes: ["analytics"],
					cookies: [
						[/^_ga.*$/i, "/", ".dr-knauer.at"],
						["_gid", "/", ".dr-knauer.at"],
						[/^_dc_gtm.*$/i, "/", ".dr-knauer.at"],
						[/^_gtm.*$/i, "/", ".dr-knauer.at"],
					],
					required: false,
				},
				{
					name: "facebook",
					title: "Facebook",
					purposes: ["marketing"],
					cookies: [],
					required: false,
				},
			],
			callback: function (consent, service) {

				// load facebook pixel
				// check if consent for facebook is given, check if facebook pixel is not already loaded, callback fires multiple times
				if (service.name == "facebook" && consent && facebookPixelLoaded === false) {

					!function(f,b,e,v,n,t,s)
					{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";
					n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)}(window, document,"script",
					"https://connect.facebook.net/en_US/fbevents.js");

					// eslint-disable-next-line no-undef
					fbq("init", "430749434477708");
					// eslint-disable-next-line no-undef
					fbq("track", "PageView");

					// const img = new Image();
					// img.src = "https://www.facebook.com/tr?id=430749434477708&ev=PageView&noscript=1"
					// img.style = "display: none";
					// img.width = "1";
					// img.height = "1";
					// const body = document.getElementsByTagName("body");
					// if (body.length > 0) {
					// 	body[0].appendChild(img);			
					// 	facebookPixelLoaded = true;
					// }
				}
			},
		};

		// we assign the Klaro module to the window, so that we can access it in JS
		window.klaro = Klaro;
		window.klaroConfig = config;
		// we set up Klaro with the config
		Klaro.setup(config);

	},
}