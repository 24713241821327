import "intersection-observer";
import swiper from "./swiper";
import lazyload from "./dal-lazyload";
import navigation from "./navigation"
import overlayVideo from "./overlay-video"
import accordion from "./accordion"
import header from "./header"
import forms from "./forms"
import blogOverview from "./blog-overview"
import jumpmarks from "./jumpmarks"
import AOS from "aos";
import "lazysizes";
import cookieBanner from "./cookie-banner";
// eslint-disable-next-line no-undef
require("./autoload"); //enforce to copy all images of src into web folder 

(function () {

	forms.init();
	
	lazyload.init();
	
	navigation.init();
	
	overlayVideo.init();
	
	accordion.init();
	
	blogOverview.init();
	
	header.init();
	
	jumpmarks.init();
	
	AOS.init();
	
	swiper.init();
	
	// remove comment if you want to add a cookie banner
	cookieBanner.init(); 

	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function () {
        // recreate js after sprig reloads something
        swiper.init();
        blogOverview.init();
    });
})();