import Swiper, { Navigation } from "swiper";

let cardSwiper;
let sprigSliderFilterButtons;

export default {

	init() {

		Swiper.use([Navigation]);

		// for swiper to work uncomment the vendors loading in base-web-layout.twig
		cardSwiper = new Swiper(".swiper-container-card-swiper", {
			slidesPerView: 1,
			spaceBetween: 8,
			grabCursor: true,
			navigation: {
				nextEl: ".custom-swiper-btn-next",
				prevEl: ".custom-swiper-btn-prev",
			},
			breakpoints: {
				640: {
					slidesPerView: 1.2,
					spaceBetween: 15,
				},
				768: {
					slidesPerView: 2.2,
					spaceBetween: 20,
				},
				
				2440: {
					slidesPerView: 3.2,
					spaceBetween: 30,
				},
			},
		});
			
		sprigSliderFilterButtons = new Swiper(".slider-filter-buttons-blog", {
			slidesPerView: "auto",
			grabCursor: true,
			centeredSlides: true,
			centeredSlidesBounds: true,
			watchSlidesProgress: true,
			breakpoints: {
				768: {
					slidesPerView: "auto",
					allowTouchMove: false,
					centeredSlides: false,
					centeredSlidesBounds: false,
				},
			},
		});
	},
	disable() {
		if(cardSwiper.length ) {
			cardSwiper.forEach( function(element){
				element.destroy();
			});
		} else {
			cardSwiper.destroy();
		}
		
		if(sprigSliderFilterButtons.length ) {
			sprigSliderFilterButtons.forEach( function(element){
				element.destroy();
			});
		} else {
			sprigSliderFilterButtons.destroy();
		}
	},
}
