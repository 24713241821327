import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default {
    init() {
        const videoExplanationButtons = document.getElementsByClassName("video-explanation-button");

		// find all video buttons
		videoExplanationButtons.forEach(function(button) {

			const targetLayoutId = button.dataset.targetLayoutElementId;
			if (!targetLayoutId) {
				return;
			}
			// get specific video overlay of this current layout element
			const cssTargetId = "video-overlay-" + targetLayoutId;
			const videoEmbed = document.getElementById(cssTargetId);
			if (!videoEmbed) {
				return;
			}
			// iframe src is set in data-video-src

			const videoSrc = videoEmbed.dataset.videoSrc;
			const videoiframes = videoEmbed.getElementsByClassName("responsive-iframe");
			if (!videoiframes) {
				return;
			}
			const videoiframe = videoiframes.length > 0 ? videoiframes[0] : null;
			if (!videoiframe) {
				return;
			}

			// add click handlers and set / unset src of iframe to play / stop

			button.addEventListener("click", function () {
				videoEmbed.style.display="block";
				videoiframe.src = videoSrc; // set src to load iframe
				disableBodyScroll(videoEmbed);
			});

			videoEmbed.addEventListener("click", function () {
				videoEmbed.style.display="none";
				enableBodyScroll(videoEmbed);
				videoiframe.src = ""; // reset src to reset iframe
			});
		});
    },
}