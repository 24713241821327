import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import anime from "animejs";

export default {
    init() {

        //////////// Navigation
        const menu = document.getElementById("menu");
        const hamburger = document.getElementById("hamburger");
        const content = document.querySelector(".content");
        const logoMenu = document.getElementById("logo-menu");
        // const footer = document.getElementById("footer");
        const headerButtons = document.getElementsByClassName("header-button");

        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty("--vh", `${vh}px`);

        // We listen to the resize event
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        });

        if (hamburger) {
            hamburger.addEventListener("click", function () {

                const timeline = anime.timeline({ duration: 400, easing: "easeOutQuint" });

                // close menu
                if (menu.classList.contains("open")) {
                    enableBodyScroll(content);
                    menu.classList.remove("open");
                    hamburger.classList.remove("close");
                    logoMenu.style.display = "block";
                    // footer.style.display = "block";

                    timeline.add({
                        targets: "#header-buttons-menu",
                        opacity: 0,
                        complete: () => {
                        },
                    });

                } else {
                    // open menu
                    disableBodyScroll(content);
                    menu.classList.add("open");
                    hamburger.classList.add("close");
                    logoMenu.style.display = "block";
                    // footer.style.display = "none";

                    timeline.add({
                        targets: "#header-buttons-menu",
                        opacity: 1,
                        complete: () => {
                        },
                    });
                }
            });
        }
        // underline header buttons when page active
        if (headerButtons) {
            headerButtons.forEach(headerButton => {
                if (headerButton.classList.contains("active")) {
                    headerButton.classList.remove("active");
                }
            });
        }
    },
}