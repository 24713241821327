export default {
  init() {
    const accordions = document.getElementsByClassName("accordion");
    if (!accordions) {
      return;
    }

    accordions.forEach(function (accordion) {
      const accordionCategory =
        accordion.getElementsByClassName("accordion-category");

      accordionCategory.forEach(function (category) {
        category.addEventListener("click", function (event) {
          // close all other categories when clicking one category
          const targetIndex = event.currentTarget.dataset.targetIndex;
          if (!targetIndex) {
            return;
          }
          const categoryButton = event.currentTarget.querySelector("button");
          const allCategories = accordion.querySelectorAll(
            ".accordion-category button"
          );
          // add bold font when button active
          allCategories.forEach(function (category) {
            category.classList.remove("font-raleway-bold");
          });
          categoryButton.classList.add("font-raleway-bold");

          const contentIndex = accordion.querySelector(
            "[data-content-index='" + targetIndex + "']"
          );
          // get all Content Blocks
          const allContentBlocks = accordion.querySelectorAll(
            ".accordion-rich-textfield"
          );
          allContentBlocks.forEach(function (content) {
            content.classList.add("hidden");
          });
          contentIndex.classList.remove("hidden");
        });
      });

      if (accordionCategory.length > 0) {
        // preselect first element
        accordionCategory[0].click();
      }
    });

    // mobile

    const accordionsMobile =
      document.getElementsByClassName("accordion-mobile");
    if (!accordionsMobile) {
      return;
    }

    accordionsMobile.forEach(function (accordionMobile) {
      const accordionMobileCategory = accordionMobile.getElementsByClassName(
        "accordion-mobile-category"
      );

      accordionMobileCategory.forEach(function (categoryMobile) {
        categoryMobile.addEventListener("click", function (event) {
          // close all other categories when clicking one category
          const targetIndex = event.currentTarget.dataset.targetIndex;
          if (!targetIndex) {
            return;
          }
          const contentIndex = accordionMobile.querySelector(
            "[data-content-index='" + targetIndex + "']"
          );
          if (
            categoryMobile.classList.contains("open") &&
            !contentIndex.classList.contains("hidden")
          ) {
            contentIndex.classList.add("hidden");
          }

          const chevronDownAccordionAll = accordionMobile.querySelectorAll(
            ".chevron-down-accordion svg"
          );
          const chevronDownAccordion = event.currentTarget.querySelector(
            ".chevron-down-accordion svg"
          );
          const categoryButton = event.currentTarget.querySelector("button");
          const allCategories = accordionMobile.querySelectorAll(
            ".accordion-mobile-category button"
          );
          const allCategoriesBlock = accordionMobile.querySelectorAll(
            ".accordion-mobile-category"
          );
          // add bold font when button active
          // rotate arrow when button active
          for (let i = 0; i < allCategories.length; i++) {
            allCategoriesBlock[i].classList.remove("open");
            allCategories[i].classList.remove("font-raleway-bold");
            chevronDownAccordionAll[i].classList.remove("transform");
            chevronDownAccordionAll[i].classList.remove("rotate-180");
          }

          categoryButton.classList.add("font-raleway-bold");
          categoryMobile.classList.add("open");
          chevronDownAccordion.classList.add("transform");
          chevronDownAccordion.classList.add("rotate-180");

          // get all Content Blocks
          const allContentBlocks = accordionMobile.querySelectorAll(
            ".accordion-rich-textfield"
          );
          allContentBlocks.forEach(function (contentMobile) {
            contentMobile.classList.add("hidden");
          });
          if (categoryMobile.classList.contains("open")) {
            contentIndex.classList.remove("hidden");
          } else {
            contentIndex.classList.add("hidden");
          }
        });
      });

      if (accordionMobileCategory.length > 0) {
        // preselect first element
        accordionMobileCategory[0].click();
      }
    });
  },
};
